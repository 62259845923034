.notification-top-bar {
	position: fixed;
	top: 0;
	left: 0;
	line-height: 40px;
	width: 100%;
	background: #caffdb;
	text-align: center;
	color: #595959;
	font-family: sans-serif;
	font-weight: lighter;
	font-size: 14px;
	z-index: 9999
}

.notification-top-bar p {
	padding: 0;
	margin: 0;
}

.notification-top-bar p a {
	padding: 5px 10px;
	border-radius: 3px;
	background: #116522;
	color: #fff;
	font-weight: bold;
	text-decoration: none;
}

.notification-offset {
	top: 120px !important;
}

.notification-offset-padding {
	padding-top: 120px;
}

@media (min-width: 800px) {
	.notification-offset {
		top: 40px !important;
	}

	.notification-offset-padding {
		padding-top: 40px;
	}
}

@media (min-width: 450px) and (max-width: 799px) {
	.notification-offset {
		top: 80px !important;
	}

	.notification-offset-padding {
		padding-top: 80px;
	}
}

#countdown {
	font-weight: bold;
	color: red;
}

	/*********************************************************
** DEFAULT RESOLUTION
*********************************************************/
	.intro-header {
		padding-top: 50px; /* make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
		padding-bottom: 0px;
		text-align: center;
	}

	.intro-message {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	#logo {
		height: 140px;
		top: 0;
		width: 220px;
		background-color: #fff;
		-webkit-border-radius: 0 0 50px 0;
		border-radius: 0 0 50px 0;
		position: fixed;
		z-index: 1700;
		-webkit-box-shadow: -5px -5px 40px 0 #000000;
		box-shadow: -5px -5px 40px 0 #000000;
	}

	/*********************************************************
** SMALL RESOLUTION
*********************************************************/
	@media (max-width: 767px) {
		#logo {
			height: 60px !important;
			width: 100px !important;
			-webkit-border-radius: 0 0 20px 0 !important;
			border-radius: 0 0 20px 0 !important;
			z-index: 1300;
		}

		.intro-message > h1 {
			margin-top: 50px;
			text-shadow: none;
			font-size: 1.75em;
		}

		.intro-message > h3 {
			text-shadow: none;
			font-size: 1.25em;
		}

		.smart {
			font-size: 1.5em;
		}

		.hideSmall {
			display: none;
		}
	}

	/*********************************************************
** MEDIUM RESOLUTION
*********************************************************/
	@media (min-width: 768px) and (max-width: 1050px) {
		#logo {
			height: 85px !important;
			width: 133px !important;
			-webkit-border-radius: 0 0 20px 0 !important;
			border-radius: 0 0 20px 0 !important;
			z-index: 1300;
		}

		.intro-message > h1 {
			margin: 0;
			text-shadow: none;
			font-size: 3em;
		}

		.hideMedium {
			display: none;
		}
	}


	.fullWidth {
		width: 90%;
	}



	/* ################## */
	.btn-lg, .btn-group-lg > .btn {
		width: 100%;
		min-height: 150px;
		padding: 30px 10px 0px 10px;
		font-size: 18px;
		line-height: 1.3333333;
		border-radius: 0 !important;
	}

	.btn:hover {
		color: #fff !important;
	}

	.btn:focus {
		color: #fff !important;
	}
	/* ###### new button color CSS-Vorlage ###### */
	.xxx {
		color: #fff;
		background-color: #xxx;
		border-color: #xxx;
	}

		.xxx:hover {
			color: #fff;
			background-color: rgba(255,255,255,.8);
			border-color: rgba(255,255,255,.8);
		}
	/* ###### END new button color CSS-Vorlage ###### */

	/* ###### new button colors ###### */
	.btn-connect {
		color: #fff;
		background-color: #5c3965;
		border-color: #5c3965;
	}

		.btn-connect:hover {
			color: #fff;
			background-color: rgba(92,57,101,.8);
			border-color: rgba(92,57,101,.8);
		}

	.btn-plantManager {
		color: #fff;
		background-color: #92c581;
		border-color: #92c581;
	}

		.btn-plantManager:hover {
			color: #fff;
			background-color: rgba(146,197,129,.8);
			border-color: rgba(146,197,129,.8);
		}

	.btn-boxManager {
		color: #fff;
		background-color: #fbba00;
		border-color: #fbba00;
	}

		.btn-boxManager:hover {
			color: #fff;
			background-color: rgba(251,186,0,.8);
			border-color: rgba(251,186,0,.8);
		}

	.btn-downloads {
		color: #fff;
		background-color: #005d9d;
		border-color: #005d9d;
	}

		.btn-downloads:hover {
			color: #fff;
			background-color: rgba(0,93,157,.8);
			border-color: rgba(0,93,157,.8);
		}

	.btn-training {
		color: #fff;
		background-color: #005d9d;
		border-color: #005d9d;
	}

		.btn-training:hover {
			color: #fff;
			background-color: rgba(0,93,157,.8);
			border-color: rgba(0,93,157,.8);
		}

	.btn-airform {
		color: #fff;
		background-color: #e20031;
		border-color: #e20031;
	}

		.btn-airform:hover {
			color: #fff;
			background-color: rgba(226,0,49,.8);
			border-color: rgba(226,0,49,.8);
		}

	.btn-servicePlanner {
		color: #fff;
		background-color: #fbba00;
		border-color: #fbba00;
	}

		.btn-servicePlanner:hover {
			color: #fff;
			background-color: rgba(251,186,0,.8);
			border-color: rgba(251,186,0,.8);
		}

	.btn-partnerShop {
		color: #fff;
		background-color: #8bccc6;
		border-color: #8bccc6;
	}

		.btn-partnerShop:hover {
			color: #fff;
			background-color: rgba(139,204,198,.8);
			border-color: rgba(139,204,198,.8);
		}

	.btn-technicalData {
		color: #fff;
		background-color: #00a199;
		border-color: #00a199;
	}

		.btn-technicalData:hover {
			color: #fff;
			background-color: rgba(0,161,153,.8);
			border-color: rgba(0,161,153,.8);
		}

	.btn-claimsManager {
		color: #fff;
		background-color: #841c41;
		border-color: #841c41;
	}

		.btn-claimsManager:hover {
			color: #fff;
			background-color: rgba(132,28,65,.8);
			border-color: rgba(132,28,65,.8);
		}

	.btn-marketing {
		color: #fff;
		background-color: #885995;
		border-color: #885995;
	}

		.btn-marketing:hover {
			color: #fff;
			background-color: rgba(136,89,149,.8);
			border-color: rgba(136,89,149,.8);
		}

	.btn-projectDocuments {
		color: #fff;
		background-color: #c5006b;
		border-color: #c5006b;
	}

		.btn-projectDocuments:hover {
			color: #fff;
			background-color: rgba(197,0,107,.8);
			border-color: rgba(197,0,107,.8);
		}

	.btn-partsCatalog {
		color: #fff;
		background-color: #ee7f01;
		border-color: #ee7f01;
	}

		.btn-partsCatalog:hover {
			color: #fff;
			background-color: rgba(238,127,1,.8);
			border-color: rgba(238,127,1,.8);
		}

	.btnIsDisabled {
		/*pointer-events: none;*/
		color: #fff;
		background-color: #ccc;
		border-color: #ccc;
	}






	/* ###### new button color ###### */

	.btn-2g {
		color: #fff;
		background-color: #09631f;
		border-color: #09631f;
	}

		.btn-2g:focus, .btn-2g.focus {
			color: #fff;
			background-color: #449d44;
			border-color: #255625;
		}

		.btn-2g:hover {
			color: #fff;
			background-color: #449d44;
			border-color: #398439;
		}

	.btn-2g-orange {
		color: #fff;
		background-color: #ff8c00;
		border-color: #ff8c00;
	}

		.btn-2g-orange:focus, .btn-2g-orange.focus {
			color: #fff;
			background-color: #f4aa4f;
			border-color: #f4aa4f;
		}

		.btn-2g-orange:hover {
			color: #fff;
			background-color: #f4aa4f;
			border-color: #f4aa4f;
		}

	.btn-info {
		color: #fff;
		background-color: #1874cd;
		border-color: #1874cd;
	}

		.btn-info:focus, .btn-info.focus {
			color: #fff;
			background-color: #31b0d5;
			border-color: #31b0d5;
		}

		.btn-info:hover {
			color: #fff;
			background-color: #31b0d5;
			border-color: #31b0d5;
		}

	.btn-danger {
		color: #fff;
		background-color: #d9534f;
		border-color: #d43f3a;
	}

		.btn-danger:focus, .btn-danger.focus {
			color: #fff;
			background-color: #c9302c;
			border-color: #761c19;
		}

		.btn-danger:hover {
			color: #fff;
			background-color: #c9302c;
			border-color: #ac2925;
		}

	.img-ile img {
		height: 60px;
		width: auto;
		margin-bottom: 15px;
	}

	.centered {
		margin: 0 auto !important;
	}

	.pre {
		white-space: pre;
	}

	/* styles Schulungsangebot im Trainings-Center */
	.typeTable {
		font-size: 16px;
		font-weight: normal;
	}

	.faSize {
		font-size: 36px !important;
	}

	.faStars {
		position: relative;
		top: -25px;
		left: -5px;
		font-size: 10px !important;
	}

	.headline {
		font-weight: normal;
		color: #09631f;
	}

	.typColour {
		color: #09631f;
	}

	.tabCenter {
		text-align: center;
	}

	.vertAlign {
		vertical-align: middle !important;
	}


	/* new header plantmanager */
	@media only screen and (min-width: 320px) and (max-width: 1199px) {
		.boxAktuelleDaten {
			margin-bottom: 25px;
		}
	}

	.topBox {
		padding: 10px 20px 10px 20px;
		color: #000 !important;
		background-color: whitesmoke;
		border: 1px solid lightgray;
	}

	.boxAktuelleDaten {
		border: solid 2px #aaa;
		min-height: 110px;
	}

	.inBox1 {
		border-bottom: solid 2px #aaa;
		padding: 5px;
		height: 55px;
	}

	.inBox1a {
		padding: 10px 0;
	}

	.inBox2 {
		padding-top: 12px;
	}
	/* END new header plantmanager */

	/* TableBox */
	.tableBox > thead > tr > th, .tableBox > tbody > tr > th, .tableBox > tfoot > tr > th, .tableBox > thead > tr > td, .tableBox > tbody > tr > td, .tableBox > tfoot > tr > td {
		padding: 0px;
		line-height: 1.42857143;
		vertical-align: center;
		border-top: 0px solid #ddd;
		font-size: 12px !important;
	}

	.tableHeader {
		width: 100% !important;
		height: auto;
		font-size: 12px !important;
		text-align: left;
	}

	.datenBox2 {
		/*height: 345px !important;*/
		/*height: 475px !important;*/
		background-color: transparent;
		margin-top: 30px;
	}

	.datenBoxContent2 {
		/* height: 270px; */
		/*height: 400px; */
		padding: 0px 0px;
		margin-top: 0px !important;
		border-left: solid 1px #ccc;
		border-right: solid 1px #ccc;
		border-bottom: solid 1px #ccc;
	}

	.datenBoxHeader {
		height: 87px;
		padding: 5px;
		color: #fff;
		border-top: solid 1px #ccc;
		border-right: solid 1px #ccc;
		border-left: solid 1px #ccc;
	}

	div.iconbox {
		float: left;
		text-align: center;
		border: 1px solid #ccc;
		background: rgba(255,255,255,0.1);
		padding: 2px 10px 2px 10px;
		margin: 2px 5px 0px 0px;
		display: inline-block;
		cursor: pointer;
	}

		div.iconbox:hover {
			border: 2px solid #000;
		}

	div.iconboxcontainer {
		vertical-align: top;
	}

	.legendImg {
		float: left;
		margin-right: 10px;
	}

	.legendPad {
		padding: 5px 0 0 25px;
	}



	/* ========= new legend =========== */
	.marginbot20 {
		margin-bottom: 20px !important;
	}

	.boxLeft {
		height: 120px;
		background-color: #efefef;
		border-radius: 4px;
		margin-top: 10px;
	}

	.type {
		font-size: 14px;
		font-weight: bold;
	}

	.checkbox label:after {
		content: '';
		display: table;
		clear: both;
	}

	.checkbox .cr {
		position: relative;
		display: inline-block;
		border-radius: 0;
		width: 1.3em;
		height: 1.3em;
		float: left;
		margin-right: .5em;
	}

	.checkboxOrange .cr {
		background-color: #ff8c00;
		border: 1px solid #ff8c00;
		margin-left: -20px !important;
	}

	.checkboxLightGray .cr {
		background-color: #bbb;
		border: 1px solid #bbb;
		margin-left: -20px !important;
	}

	.checkboxOutline .cr {
		background-color: #fff;
		border: 1px solid #000;
		margin-left: -20px !important;
	}

	.checkboxGray .cr {
		background-color: #666;
		border: 1px solid #666;
		margin-left: -20px !important;
	}

		.checkboxGray .cr .cr-icon {
			color: #fff !important;
		}

	.checkboxRed .cr {
		background-color: #d9534f;
		border: 1px solid #d9534f;
		margin-left: -20px !important;
	}

		.checkboxRed .cr .cr-icon {
			color: #fff !important;
		}

	.checkboxYellow .cr {
		background-color: #ffff00;
		border: 1px solid #ffff00;
		margin-left: -20px !important;
	}

	.checkboxGreen .cr {
		background-color: #09631f;
		border: 1px solid #09631f;
		margin-left: -20px !important;
	}

		.checkboxGreen .cr .cr-icon {
			color: #fff !important;
		}

	.checkboxLightGreen .cr {
		background-color: #449d44;
		border: 1px solid #449d44;
		margin-left: -20px !important;
	}

		.checkboxLightGreen .cr .cr-icon {
			color: #fff !important;
		}

	.checkboxBlue .cr {
		background-color: #1874cd;
		border: 1px solid #1874cd;
		margin-left: -20px !important;
	}

		.checkboxBlue .cr .cr-icon {
			color: #fff !important;
		}

	.checkbox .cr .cr-icon {
		position: absolute;
		font-size: .8em;
		line-height: 0;
		top: 45%;
		left: 20%;
		color: #000;
	}

	.checkbox label input[type="checkbox"] {
		display: none;
	}

		.checkbox label input[type="checkbox"] + .cr > .cr-icon {
			transform: scale(3) rotateZ(-20deg);
			opacity: 0;
			transition: all .3s ease-in;
		}

		.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
			transform: scale(1) rotateZ(0deg);
			opacity: 1;
		}

		.checkbox label input[type="checkbox"]:disabled + .cr {
			opacity: .5;
		}
	/* ========= new legend =========== */

	.textBlack {
		color: #000;
	}

	.datenBoxHeader td {
		font-weight: 400;
		border: none !important;
	}

	.datenBoxContent td {
		font-weight: 400;
		border: none !important;
	}

	.bgYellow {
		background-color: #ffff00;
		color: #000 !important;
	}

	.bgGreen {
		background-color: #09631f;
		color: #fff !important;
	}

	.bgLightGreen {
		background-color: #449d44;
		color: #fff !important;
	}

	.bgOrange {
		background-color: #ff8c00;
		color: #fff !important;
	}

	.bgRed {
		background-color: #d9534f;
		color: #fff !important;
	}

	.bgBlue {
		background-color: #1874cd;
		color: #fff !important;
	}

	.bgGray {
		background-color: #666;
		color: #fff !important;
	}

	.bgLightGray {
		background-color: #bbb;
		color: #000 !important;
	}

	.bgLightestGray {
		background-color: #efefef;
		color: #000 !important;
	}

	/* links TableBox */
	.report:link, .report:visited, .report:focus {
		font-weight: 600;
		color: #666;
		text-decoration: none;
	}

	.report:hover, .report:active {
		color: #000;
		text-decoration: none;
	}

	/* TableBox END */

	a {
		outline-style: none !important;
	}
	/* panel styles */
	.panel-group {
		margin-bottom: 0px;
	}

		.panel-group .panel {
			/*margin-bottom: -6px !important;*/
			margin-top: 0px !important;
			border-radius: 0px;
			font-weight: normal !important;
			text-decoration: none;
		}

	.panel-default:first-child {
		border-top: 0 !important;
	}

	.panel-default {
		border-color: #ddd;
		border-left: 0 !important;
		border-right: 0 !important;
		border-bottom: 0 !important;
	}

		.panel-default > .panel-heading {
			color: #666;
			background-color: #f5f5f5;
			border-color: #ddd;
		}

	.panel-group .panel-heading {
		border-bottom: 0;
		margin-bottom: 0;
	}

	.panel-heading {
		padding: 5px 15px;
		border-bottom: 1px solid transparent;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.panel-title {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 14px;
		color: inherit;
		font-weight: normal !important;
		text-align: center;
		text-decoration: none;
	}

		.panel-title a:hover, .panel-title a:focus {
			text-decoration: none;
		}

	.panel-body {
		padding: 5px 5px 5px 10px;
		min-height: 115px;
	}

	.panel-body-plantmanager {
		padding: 5px 5px 5px 10px;
		min-height: 273px;
		max-height: 273px;
		overflow-y: auto;
		width: 400px;
	}

	/* panel styles END */


	/* tooltip style */
	.tooltip {
		opacity: 1 !important;
	}

		.tooltip.grey-tooltip .tooltip-inner {
			color: #ffffff;
			background-color: rgba(96,96,96,1.0);
			box-shadow: 0 6px 12px rgba(0,0,0,.5);
		}

		.tooltip.grey-tooltip .tooltip-arrow {
			border-top-color: rgba(96,96,96,1.0);
		}
	/* tooltip style END*/


	@media only screen and (min-width: 320px) and (max-width: 1024px) {
		h4 {
			font-size: 14px !important;
		}
	}

	/* style MODAL */
	.close {
		float: right;
		font-size: 21px;
		font-weight: bold;
		line-height: 1;
		color: #fff;
		text-shadow: 0 0px 0 #fff;
		filter: alpha(opacity=20);
		opacity: 1.0;
	}

		.close:hover, .close:focus {
			color: #fff;
			text-decoration: none;
			cursor: pointer;
			filter: alpha(opacity=50);
			opacity: .5;
		}

	.modal-title {
		color: #fff;
	}
	/* ################################ */
	/* landing-page.css old 160824 */

	/*!
 * Start Bootstrap - Landing Page Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
	/* 2G-green > #09631f */
	/*html {overflow-y: scroll;}*/
	body, html {
		width: 100%;
		height: 100%;
		color: #666 !important;
	}

	body, h1, h2, h3, h4, h5, h6 {
		font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-weight: 700;
	}
	/* ------------------------------ */
	/* lines */
	/* ------------------------------ */
	hr {
		border-color: #bbb;
	}

	.fullHR {
		max-width: 100%;
		border-color: #999999;
		/*margin: 30px 15px !important;*/
	}

	.hr-small-grey {
		max-width: 400px;
		border-color: #666;
		margin: 16px auto !important;
	}

	.brand, .address-bar {
		display: none;
	}

	/* styles rg ---------------------------------- */
	#branding {
		height: 75px;
		width: 115px;
		top: 15px;
		background-color: transparent;
		position: fixed;
		z-index: 1000;
	}

		#branding img {
			height: 75px;
			width: auto;
		}

	.form-control:focus {
		border-color: #09631f;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(9, 99, 31, .6);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(9, 99, 31, .6);
	}

	a {
		color: #09631f;
		text-decoration: none;
	}

		a:hover, a:focus {
			color: #000;
			text-decoration: underline;
		}

	.centerd {
		margin: 0 auto !important;
	}

	.marTop30 {
		margin-top: 30px;
	}

	.marTop75 {
		margin-top: 75px;
	}

	.lightGrey {
		color: #ccc;
	}

	.red {
		color: #f00;
	}

	.yellow {
		color: #ff0;
	}

	.green {
		color: #0f0;
	}

	.greenPrint {
		color: #09631f;
	}

	.tile-border {
		border: 5px solid #fff;
	}

	.no-gutter > [class*=col-] {
		padding-top: 0 !important;
		padding-right: 0 !important;
		padding-left: 0 !important;
		padding-bottom: 0 !important;
	}

	.no-gutter-ile > [class*=col-] {
		padding-top: 0 !important;
		padding-right: 6px !important;
		padding-left: 0 !important;
		padding-bottom: 6px !important;
	}

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		.no-gutter-ile > [class*=col-] {
			padding-top: 0 !important;
			padding-right: 3px !important;
			padding-left: 3px !important;
			padding-bottom: 6px !important;
		}
	}

	.navbar-default {
		background-color: #f8f8f8;
		border-color: #e7e7e7;
		position: fixed;
		z-index: 1030;
		top: 0;
		right: 0;
		left: 0;
		border-width: 0 0 1px;
	}

		.navbar-default .navbar-brand {
			color: #777;
		}

			.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
				color: #5e5e5e;
				background-color: transparent;
			}

		.navbar-default .navbar-text {
			color: #fff;
		}

		.navbar-default .navbar-nav > li > a {
			color: #fff;
		}

			.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
				color: #36cc5a;
				background-color: transparent;
			}

		.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
			color: #09631f;
			background-color: #e7e7e7;
			/*background-color: rgba(0, 0, 0, .175);*/
		}

		.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
			color: #ccc;
			background-color: transparent;
		}

		.navbar-default .navbar-toggle {
			border-color: #fff;
		}

			.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
				background-color: #449d44;
			}

			.navbar-default .navbar-toggle .icon-bar {
				background-color: #fff;
			}

		.navbar-default .navbar-collapse, .navbar-default .navbar-form {
			border-color: #e7e7e7;
		}

		.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
			color: #fff;
			background-color: #09631f; /*rg*/
		}

	.dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1000;
		display: none;
		float: left;
		min-width: 60px; /*rg*/
		padding: 5px 0;
		margin: 2px 0 0;
		font-size: 14px;
		text-align: left;
		list-style: none;
		background-color: #fff;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: 1px solid #ccc;
		border: 1px solid rgba(0, 0, 0, .15);
		border-radius: 4px;
		-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
		box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	}

	@media (max-width: 767px) {
		.navbar-default .navbar-nav .open .dropdown-menu > li > a {
			color: #fff;
		}

			.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
				color: #fff;
				background-color: transparent;
			}

		.navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
			color: #555;
			background-color: #e7e7e7;
		}

		.navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
			color: #ccc;
			background-color: transparent;
		}
	}

	.navbar-default .navbar-link {
		color: #777;
	}

		.navbar-default .navbar-link:hover {
			color: #333;
		}

	.navbar-default .btn-link {
		color: #777;
	}

		.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
			color: #333;
		}

		.navbar-default .btn-link[disabled]:hover, fieldset[disabled] .navbar-default .btn-link:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:focus {
			color: #ccc;
		}
	/* styles rg END  ----------------------------- */
	.topnav {
		font-size: 14px;
		background-color: #09631f;
	}

	.lead {
		font-size: 18px;
		font-weight: 400;
	}



	.intro-message > h1 {
		margin: 0;
		font-size: 3em;
	}

	.intro-divider {
		width: 400px;
		border-top: 1px solid #f8f8f8;
		border-bottom: 1px solid rgba(0,0,0,0.2);
	}

	.intro-message > h3 {
	}

	@media (max-width: 767px) {
		.intro-message {
			padding-bottom: 10px;
		}

			.intro-message > h1 {
				font-size: 3em;
			}

		ul.intro-social-buttons > li {
			display: block;
			margin-bottom: 20px;
			padding: 0;
		}

			ul.intro-social-buttons > li:last-child {
				margin-bottom: 0;
			}

		.intro-divider {
			width: 100%;
		}
	}


	.network-name {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 2px;
	}

	.content-section-a {
		padding: 50px 0;
		background-color: #f8f8f8;
	}

	.content-section-b {
		padding: 50px 0;
		border-top: 1px solid #e7e7e7;
		border-bottom: 1px solid #e7e7e7;
	}

	.section-heading {
		margin-bottom: 30px;
	}

	.section-heading-spacer {
		float: left;
		width: 200px;
		border-top: 3px solid #e7e7e7;
	}

	.banner {
		padding: 100px 0;
		color: #f8f8f8;
		background: url(../img/banner-bg.jpg) no-repeat center center;
		background-size: cover;
	}

		.banner h2 {
			margin: 0;
			text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
			font-size: 3em;
		}

		.banner ul {
			margin-bottom: 0;
		}

	.banner-social-buttons {
		float: right;
		margin-top: 0;
	}

	@media (max-width: 1199px) {
		ul.banner-social-buttons {
			float: left;
			margin-top: 15px;
		}
	}

	@media (max-width: 767px) {
		.banner h2 {
			margin: 0;
			text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
			font-size: 3em;
		}

		ul.banner-social-buttons > li {
			display: block;
			margin-bottom: 20px;
			padding: 0;
		}

			ul.banner-social-buttons > li:last-child {
				margin-bottom: 0;
			}
	}

	footer {
		padding: 50px 0;
		background-color: #f8f8f8;
	}

	p.copyright {
		margin: 15px 0 0;
	}

	.bg-orange {
		background-color: #ff8c00;
	}

	.ersatzteile-sevice {
		background: /*#09631f*/ url(../img/icons/Icon_2G-Original-Ersatzteile-white.png) no-repeat center center;
		/*background-size: 97px 118px;*/
		background-size: cover;
	}

	.online-hilfe {
		background: /*#ff8c00*/ url(../img/icons/Icon_2G-Original-Ersatzteile-white.png) no-repeat center center;
		/*background-size: 118px 79px;*/
		background-size: cover;
	}

	.motorservice {
		background: /*#09631f*/ url(../img/icons/icon600.png) no-repeat center center;
		/*background-size: 118px 118px;*/
		background-size: cover;
	}

	.portfolio-box {
		display: block;
		position: relative;
		margin: 0 auto;
		width: 100%;
		height: 190px !important;
		border: solid 4px #fff !important;
		overflow: hidden;
	}

		.portfolio-box .portfolio-box-caption {
			display: block;
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 100%;
			text-align: center;
			color: #fff;
			opacity: 0;
			background: rgba(9, 99, 31,.8);
			-webkit-transition: all .9s;
			-moz-transition: all .9s;
			transition: all .9s;
		}

			.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
				position: absolute;
				top: 50%;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}

				.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category, .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
					padding: 0 15px;
					font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
				}

				.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 600;
				}

				.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
					font-size: 18px;
				}

		.portfolio-box:hover .portfolio-box-caption {
			opacity: 1;
		}

	@media (min-width: 768px) {
		.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category h4 {
			font-size: 16px;
			text-transform: uppercase;
		}

		.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category p {
			font-size: 14px;
			text-transform: none;
			.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
		font-size: 22px;
	}
}

/* ################################ */

.thumbnail {
	position: relative;
	overflow: hidden;
}

.thumbnail {
	display: block;
	max-height: 187px;
	padding: 0 4px 0 0;
	margin-bottom: 20px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 0px solid #09631f;
	border-radius: 0px;
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
}

.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 100%;
	font-weight: bold;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
}
.label-default {
	background-color: #000;
}

.caption {
	position: absolute;
	top: -100%;
	right: 0;
	background: rgba(255, 255, 255, 0.6);
	height: 100%;
	padding: 5% 15% !important;
	text-align: center;
	color: #000 !important;
	z-index: 2;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.thumbnail:hover .caption {
	top: 0%;
}

.btn-warranty {
	color: #fff;
	background-color: #09631f;
	border-color: #09631f;
}
.btn-warranty:focus, .btn-warranty.focus {
	color: #333;
	background-color: #e6e6e6;
	border-color: #8c8c8c;
}
.btn-warranty:hover {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad;
}
.btn-warranty:active, .btn-warranty.active, .open > .dropdown-toggle.btn-warranty {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad;
}
.btn-warranty:active:hover, .btn-warranty.active:hover, .open > .dropdown-toggle.btn-warranty:hover, .btn-warranty:active:focus, .btn-warranty.active:focus, .open > .dropdown-toggle.btn-warranty:focus, .btn-warranty:active.focus, .btn-warranty.active.focus, .open > .dropdown-toggle.btn-warranty.focus {
	color: #333;
	background-color: #d4d4d4;
	border-color: #8c8c8c;
}
.btn-warranty:active, .btn-warranty.active, .open > .dropdown-toggle.btn-warranty {
	background-image: none;
}
.btn-warranty.disabled:hover, .btn-warranty[disabled]:hover, fieldset[disabled] .btn-warranty:hover, .btn-warranty.disabled:focus, .btn-warranty[disabled]:focus, fieldset[disabled] .btn-warranty:focus, .btn-warranty.disabled.focus, .btn-warranty[disabled].focus, fieldset[disabled] .btn-warranty.focus {
	background-color: #fff;
	border-color: #ccc;
}
.btn-warranty .badge {
	color: #fff;
	background-color: #333;
}

.plantManager-info-box {
	min-height: 20px;
	width: 100%;
	padding: 20px;
	margin: 0 0 20px 0;
	border: 1px solid lightgray;
	font: normal 9pt Arial;
	/*background-color:whitesmoke;*/
	/*-webkit-animation: fadein 1s;
	animation: fadein 1s;*/
}
	
	.plantManager-info-box div:nth-of-type(1) {
		width: 50px;
		height: 70px;
		float: left;
		background: repeating-linear-gradient(45deg, transparent, transparent 2px, #ffffff 1px, #ffffff 20px), linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
		border: 1px solid gray;
		margin-right: 10px;
    }

	.plantManager-info-box div:nth-of-type(2) {
		float: left;
		width: calc(50% - 60px);
		white-space: pre-line;
		margin-top:-10px;
	}

	.plantManager-info-box div:nth-of-type(3) {
		width: 50px;
		height: 70px;
		float: left;
		border: 1px solid gray;
		margin-right: 10px;
		background-color: #ffe163;
		opacity: 1;
		background: radial-gradient(circle, transparent 20%, #ffe163 20%, #ffe163 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #ffe163 20%, #ffe163 80%, transparent 80%, transparent) 20px 20px, linear-gradient(darkslategray 1.6px, transparent 1.6px) 0 -0.8px, linear-gradient(90deg, darkslategray 1.6px, #ffe163 1.6px) -0.8px 0;
		background-size: 40px 40px, 40px 40px, 20px 20px, 20px 20px;
	}

	.plantManager-info-box div:nth-of-type(4) {
		float: left;
		width: calc(50% - 60px);
		white-space: pre-line;
		margin-top: -10px;
	}

.plantManager-disclaimer {
	padding: 5px 20px;
	width: 100%;
	background-color: darkslategray;
	color: yellow;
	font: normal 10pt arial;
}

.headline-ext {	
	border-bottom: 1px solid gray;	
	padding:10px 0 20px 0;
	margin-bottom:20px;
}


	.headline-ext h1 {
		padding-left: 200px;
	}
	.headline-ext h1, h2, h3, h4 {
		display: inline;		
	}
	.headline-ext h4 {
		font: normal 11pt Consolas;
		color: darkslategray;
		float: right;
		text-align: right;
		vertical-align: text-bottom;
		-webkit-animation: fadein 4s;
		animation: fadein 4s;
	}
	.poweredbyiris img {
		width:60px;
		margin: -5px 5px 2px 0;
		/*-webkit-animation: fadeinandout 3s ease 0s 1 normal forwards;
		animation: fadeinandout 2s ease 0s 1 normal forwards;*/
		-webkit-animation: sizein 2s;
		animation: sizein 2s;		
		/*border-radius: 30px;*/
		/*background: radial-gradient(circle at 50% 65%, lawngreen 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);*/
	}

	.poweredbyiris {
		display:inline;
	}
	.poweredbyiris:hover .iris-tip {
		visibility: visible;
	}

	.periodselection:hover .period-intern-tip {
		visibility: visible;
	}

	.iris-tip {
		top: 80px;
		right: 0;
		width: 500px;
	}

	.period-intern-tip {
		top: 80px;
		right: 20%;
		width: 500px;
	}

	.tooltiptext {
		visibility: hidden;		
		background-color: darkslategray;
		color: yellow;
		text-align: left;
		padding: 15px;
		border-radius: 6px;
		position: absolute;
		z-index: 1;		
		font: normal 12pt Calibri;
	}

@-webkit-keyframes fadein {
	from {opacity: 0;}
	to {opacity: 1;}
}
@keyframes fadein {
	from {opacity: 0;}
	to {opacity: 1;}
}
@-webkit-keyframes sizein {
	from {width: 0px;}
	to {width: 60px;}
}
@keyframes sizein {
	from {width: 0px;}
	to {width: 60px;}
}
@-webkit-keyframes fadeinandout {
	0% {opacity: 0;}
	33% {opacity: 1;}
	66% {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fadeinandout {
	0% {opacity: 0;}
	33% {opacity: 1;}
	66% {opacity: 0;}
	100% {opacity: 1;}
}