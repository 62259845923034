/*!
 * Start Bootstrap - Landing Page Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
/* 2G-green > #09631f */
/*html {overflow-y: scroll;}*/
body,
html {
    width: 100%;
    height: 100%;
    color: #666 !important;
    
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}

/* ------------------------------ */
/* lines */
/* ------------------------------ */
hr {
	border-color: #bbb;
}
.fullHR {
	max-width: 100%;
	border-color: #999999;
	/*margin: 30px 15px !important;*/
}

.hr-small-grey {
	max-width: 400px;
	border-color: #666;
	margin: 16px auto !important;
}

.brand, .address-bar {
	display: none;
}

/* styles rg ---------------------------------- */
#branding {
    height: 75px;
    width: 115px;
    top: 15px;
    background-color: transparent;
    position: fixed;
    z-index: 1000;
}
#branding img {height: 75px; width: auto;}

.form-control:focus {
  border-color: #09631f;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(9, 99, 31, .6);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(9, 99, 31, .6);
}



a {
  color: #09631f;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #000;
  text-decoration: underline;
}
.centerd {margin: 0 auto !important;}
.marTop30 {margin-top: 30px;}

.marTop75 {margin-top: 75px;}

.lightGrey {color: #ccc;}

.red {color: #f00;}
.yellow {color: #ff0;}
.green {color: #0f0;}
.greenPrint {color: #09631f;}


.tile-border {border: 5px solid #fff;}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
.navbar-default .navbar-brand {
  color: #777;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}
.navbar-default .navbar-text {
  color: #fff;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #36cc5a;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #09631f;
  background-color: #e7e7e7;
 /*background-color: rgba(0, 0, 0, .175);*/ 
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  border-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #449d44;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #09631f; /*rg*/
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 60px; /*rg*/
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-link {
  color: #777;
}
.navbar-default .navbar-link:hover {
  color: #333;
}
.navbar-default .btn-link {
  color: #777;
}
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #333;
}
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}
/* styles rg END  ----------------------------- */

.topnav {
    font-size: 14px; 
    background-color: #09631f;
}

.lead {
    font-size: 18px;
    font-weight: 400;
}

.intro-header {
    padding-top: 0px; /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
    padding-bottom: 0px;
    text-align: center;
    /*color: #f8f8f8;
    background: url(../img/12_2g-service-01.jpg) no-repeat center center;
    background-size: cover;*/
}

.intro-message {
    position: relative;
    padding-top: 10%;
    padding-bottom: 0%;
}

.intro-message > h1 {
    margin: 0;
    /*text-shadow: 2px 2px 3px rgba(0,0,0,0.6);*/
    font-size: 3em;
}

.intro-divider {
    width: 400px;
    border-top: 1px solid #f8f8f8;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.intro-message > h3 {
    /*text-shadow: 2px 2px 3px rgba(0,0,0,0.6);*/
}

@media(max-width:767px) {
    .intro-message {
        padding-bottom: 15%;
    }

    .intro-message > h1 {
        font-size: 3em;
    }

    ul.intro-social-buttons > li {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    ul.intro-social-buttons > li:last-child {
        margin-bottom: 0;
    }

    .intro-divider {
        width: 100%;
    }
}

.network-name {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
}

.content-section-a {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.content-section-b {
    padding: 50px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.section-heading {
    margin-bottom: 30px;
}

.section-heading-spacer {
    float: left;
    width: 200px;
    border-top: 3px solid #e7e7e7;
}

.banner {
    padding: 100px 0;
    color: #f8f8f8;
    background: url(../img/banner-bg.jpg) no-repeat center center;
    background-size: cover;
}

.banner h2 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    font-size: 3em;
}

.banner ul {
    margin-bottom: 0;
}

.banner-social-buttons {
    float: right;
    margin-top: 0;
}

@media(max-width:1199px) {
    ul.banner-social-buttons {
        float: left;
        margin-top: 15px;
    }
}

@media(max-width:767px) {
    .banner h2 {
        margin: 0;
        text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
        font-size: 3em;
    }

    ul.banner-social-buttons > li {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    ul.banner-social-buttons > li:last-child {
        margin-bottom: 0;
    }
}

footer {
    padding: 50px 0;
    background-color: #f8f8f8;
}

p.copyright {
    margin: 15px 0 0;
}


.bg-orange {background-color: #ff8c00;}


.ersatzteile-sevice {
	background: /*#09631f*/ url(../img/icons/Icon_2G-Original-Ersatzteile-white.png) no-repeat center center;
    /*background-size: 97px 118px;*/
   background-size: cover;
}
.online-hilfe {
	background: /*#ff8c00*/ url(../img/icons/Icon_2G-Original-Ersatzteile-white.png) no-repeat center center;
    /*background-size: 118px 79px;*/
    background-size: cover;
}
.motorservice {
	background: /*#09631f*/ url(../img/icons/icon600.png) no-repeat center center;
    /*background-size: 118px 118px;*/
    background-size: cover;
}

.portfolio-box {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 190px !important;
    border: solid 4px #fff !important;
    overflow: hidden;
    /*background-color: #09631f;*/
}

.portfolio-box .portfolio-box-caption {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    opacity: 0;
    background: rgba(9, 99, 31,.8);
    /*background: rgba(255, 255, 255, 0.3);*/
    /*background: rgba(0, 0, 0, 0.3);*/
    -webkit-transition: all .9s;
    -moz-transition: all .9s;
    transition: all .9s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    padding: 0 15px;
    font-family: 'Open Sans','Helvetica Neue',Arial,sans-serif;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 18px;
}

.portfolio-box:hover .portfolio-box-caption {
    opacity: 1;
}

@media(min-width:768px) {
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category h4 {
        font-size: 16px;
        text-transform: uppercase;
        
    }
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category p {
        font-size: 14px;
        text-transform: none;
        

    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
        font-size: 22px;
    }
}

/* ################################ */

.thumbnail {
    position:relative;
    overflow:hidden;
}

.thumbnail {
    display: block;
    max-height: 187px;
    padding: 0 4px 0 0;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 0px solid #09631f;
    border-radius: 0px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 100%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    
}
.label-default {
    background-color: #000;
}
 
.caption {
    position:absolute;
    top:-100%;
    right:0;
    /*background:rgba(66, 139, 202, 0.75);*/
    background: rgba(255, 255, 255, 0.6);
    /*background: rgba(0, 0, 0, 0.6);*/
    height:100%;
    /*padding:2%;*/
    padding: 5% 15% !important;
    text-align:center;
    color:#000 !important;
    z-index:2;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.thumbnail:hover .caption {
    top:0%;
}

.btn-warranty {
  color: #fff;
  background-color: #09631f;
  border-color: #09631f;
}
.btn-warranty:focus,
.btn-warranty.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-warranty:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-warranty:active,
.btn-warranty.active,
.open > .dropdown-toggle.btn-warranty {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-warranty:active:hover,
.btn-warranty.active:hover,
.open > .dropdown-toggle.btn-warranty:hover,
.btn-warranty:active:focus,
.btn-warranty.active:focus,
.open > .dropdown-toggle.btn-warranty:focus,
.btn-warranty:active.focus,
.btn-warranty.active.focus,
.open > .dropdown-toggle.btn-warranty.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-warranty:active,
.btn-warranty.active,
.open > .dropdown-toggle.btn-warranty {
  background-image: none;
}
.btn-warranty.disabled:hover,
.btn-warranty[disabled]:hover,
fieldset[disabled] .btn-warranty:hover,
.btn-warranty.disabled:focus,
.btn-warranty[disabled]:focus,
fieldset[disabled] .btn-warranty:focus,
.btn-warranty.disabled.focus,
.btn-warranty[disabled].focus,
fieldset[disabled] .btn-warranty.focus {
  background-color: #fff;
  border-color: #ccc;
}
.btn-warranty .badge {
  color: #fff;
  background-color: #333;
}




